import { FileIdentifications } from '@mortgage-pos/types'

export const fnmaFileIdentifications: FileIdentifications[] = [
  {
    fileIdentificationType: '1',
    fileVersionId: '3.20',
    fileExportVersionIndicator: 'W',
  },
  {
    fileIdentificationType: '70',
    fileVersionId: '3.20',
  },
  {
    fileIdentificationType: '11',
    fileVersionId: '3.20',
  },
  {
    fileIdentificationType: '20',
    fileVersionId: '3.20',
  },
  {
    fileIdentificationType: '30',
    fileVersionId: '3.20',
  },
]
