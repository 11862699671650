export const LoanSetupChecklistItems = {
  sage: [
    {
      name: 'Verify E-Sign',
    },
    {
      name: 'Verify ITP',
    },
    {
      name: 'Appraisal',
    },
    {
      name: 'Fraud',
    },
    {
      name: 'Title & Closing',
    },
    {
      name: 'Order Flood',
    },
    {
      name: 'USPS Validation',
    },
    {
      name: 'Move Milestone to Recd in Processing',
    },
  ],
  quicken: [],
  ameriSave: [
    {
      name: 'Appraisal',
    },
    {
      name: 'Title & Closing',
    },
  ],
  pennyMac: [
    {
      name: 'Appraisal',
    },
    {
      name: 'Title & Closing',
    },
  ],
  weslend: [
    {
      name: 'Verify E-Sign',
    },
    {
      name: 'Verify ITP',
    },
    {
      name: 'Appraisal',
    },
    {
      name: 'Fraud',
    },
    {
      name: 'Title & Closing',
    },
    {
      name: 'Order Flood',
    },
    {
      name: 'USPS Validation',
    },
    {
      name: 'Move Milestone to Recd in Processing',
    },
  ],
}
