/**
 * Helper function to take the results of Promise.allSettled and return a string of all errors
 * @param promiseResults an array of PromiseSettledResult (ie return of Promise.allSettled)
 * @returns String containing the append list of errors that occurred, null if none
 */
export function getRejectedReasons(
  promiseResults: PromiseSettledResult<any>[]
): string {
  const rejectedReasons = promiseResults?.reduce(
    (rejected: string[], settled) => {
      if (settled?.status === 'rejected') {
        rejected.push(settled.reason)
      }
      return rejected
    },
    []
  )
  if (rejectedReasons?.length > 0) {
    return rejectedReasons.join(' \n ')
  }
  return null
}

export function getAllSettledCounts(
  promiseResults: PromiseSettledResult<any>[]
): { success: number; rejected: number } {
  let success = 0,
    rejected = 0
  promiseResults.forEach((promise) => {
    if (promise.status == 'fulfilled') success++
    else rejected++
  })

  return { success, rejected }
}
