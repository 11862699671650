export function formDataToJSON(formData): string {
  const formDataObject = serializeFormData(formData)

  return JSON.stringify(formDataObject)
}

export function serializeFormData(formData): Record<string, any> {
  const formDataObject = {}

  for (const [key, value] of formData.entries()) {
    formDataObject[key] = value
  }

  return formDataObject
}
