export enum AlfalfaPage {
  Config = 'Config',
  AnswerSets = 'AnswerSets',
  Navigation = 'Navigation',
  Tumbleweed = 'Tumbleweed',
  AnswersMgmt = 'AnswersMgmt',
  CreateAnswerSet = 'CreateAnswerSet',
}

export enum AlfalfaNotificationType {
  Warn = 'warn',
  Info = 'info',
  Error = 'error',
  Success = 'success',
}

export interface AlfalfaMessage {
  type: AlfalfaNotificationType
  message: string
}

export interface AlfalfaConfig {
  isTestingMode?: boolean
  firstName?: string
  lastName?: string
  ssn?: string
  acctSignUpPassword?: string
  autoAcctSignUp?: boolean
}

export interface AlfalfaConfigDataObject {
  typeOfInput: 'text' | 'checkbox'
  keyName: keyof AlfalfaConfig
  heading: string
  description?: string
}
