export type calculateCostOfLoanOverTimeInput = {
  rate: number
  term: number
  loanAmount: number
  years: number
  additionalCosts: number
}

/**
 *
 * @param term (in years)
 * @returns
 */
export const calculateCostOfLoanOverTime = ({
  rate,
  term,
  loanAmount,
  years,
  additionalCosts,
}: calculateCostOfLoanOverTimeInput) => {
  if (
    isNaN(rate) ||
    isNaN(term) ||
    isNaN(loanAmount) ||
    isNaN(years) ||
    isNaN(additionalCosts)
  )
    return null

  const cumulativeInterestPayments =
    cumulativeInterestPaid(
      rate / 100 / 12,
      term * 12,
      loanAmount,
      1,
      years * 12,
      0
    ) * -1

  // cumulativeInterestPayments + Total from section A (IF discountOrCreditAmount < 0 + discountOrCreditAmount)
  return Math.round((cumulativeInterestPayments + additionalCosts) * 100) / 100
}

/**
 * Ref: https://support.microsoft.com/en-us/office/cumipmt-function-61067bb0-9016-427d-b95b-1a752af0e606
 */
const cumulativeInterestPaid = (rate, periods, value, start, end, type) => {
  rate = parseFloat(rate)
  periods = parseFloat(periods)
  value = parseFloat(value)

  if (anyIsError(rate, periods, value)) {
    throw new Error('#VALUE!')
  }

  if (rate <= 0 || periods <= 0 || value <= 0) {
    throw new Error('#NUM!')
  }

  if (start < 1 || end < 1 || start > end) {
    throw new Error('#NUM!')
  }

  if (type !== 0 && type !== 1) {
    throw new Error('#NUM!')
  }

  const payment = PMT(rate, periods, value, 0, type)
  let interest = 0

  if (start === 1) {
    if (type === 0) {
      interest = -value
    }
    start++
  }

  for (let i = start; i <= end; i++) {
    if (type === 1) {
      interest += FV(rate, i - 2, payment, value, 1) - payment
    } else {
      interest += FV(rate, i - 1, payment, value, 0)
    }
  }
  interest *= rate

  return interest
}

const anyIsError = function (...args) {
  let n = args.length
  while (n--) {
    if (args[n] instanceof Error) {
      return true
    }
  }
  return false
}

const FV = (rate, periods, payment, value, type) => {
  value = value || 0
  type = type || 0

  rate = parseFloat(rate)
  periods = parseFloat(periods)
  payment = parseFloat(payment)
  value = parseFloat(value)
  type = parseFloat(type)

  if (anyIsError(rate, periods, payment, value, type)) {
    throw new Error('#VALUE!')
  }

  let result
  if (rate === 0) {
    result = value + payment * periods
  } else {
    const term = Math.pow(1 + rate, periods)
    if (type === 1) {
      result = value * term + (payment * (1 + rate) * (term - 1)) / rate
    } else {
      result = value * term + (payment * (term - 1)) / rate
    }
  }
  return -result
}

const PMT = function (rate, periods, present, future, type) {
  future = future || 0
  type = type || 0

  rate = parseFloat(rate)
  periods = parseFloat(periods)
  present = parseFloat(present)
  future = parseFloat(future)
  type = parseFloat(type)
  if (anyIsError(rate, periods, present, future, type)) {
    throw new Error('#VALUE!')
  }

  let result
  if (rate === 0) {
    result = (present + future) / periods
  } else {
    const term = Math.pow(1 + rate, periods)
    if (type === 1) {
      result =
        ((future * rate) / (term - 1) + (present * rate) / (1 - 1 / term)) /
        (1 + rate)
    } else {
      result = (future * rate) / (term - 1) + (present * rate) / (1 - 1 / term)
    }
  }
  return -result
}
