export class EmailUtil {
  static emailRegex =
    /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/

  static createTemporaryEmail(email: string): string | null {
    const splitEmail = email?.split('@')

    if (splitEmail?.length > 1) {
      // Check if the end of string is +#
      const hasCounter = splitEmail[0].match(/\+\d+$/g)
      const numberToIncreace = parseInt(hasCounter?.toString())

      splitEmail[0] =
        hasCounter && numberToIncreace
          ? splitEmail[0].replace(
              hasCounter.toString(),
              `+${Math.floor(Math.random() * 9999)}`
            )
          : splitEmail[0].concat('+1')
      return splitEmail.join('@')
    } else {
      return null
    }
  }

  // Util used to remove counter from email if it exists
  static scrubEmail(email): string | null {
    const splitEmail = email?.split('@')

    if (splitEmail?.length > 1) {
      const hasCounter = splitEmail[0].match(/\+\d+$/g)

      if (hasCounter) {
        const username = splitEmail[0]
        const counterRemoved = username.split('+')[0]
        return counterRemoved + '@' + splitEmail[splitEmail.length - 1]
      } else {
        return email
      }
    }
  }

  static checkEmailValidity(email: string) {
    if (typeof email !== 'string') {
      return false
    }

    return Boolean(email.match(this.emailRegex))
  }
}
