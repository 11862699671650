import { BorrowerClassification, CreditScoreSources } from '../corelogic'

export enum CreditReportScoreSource {
  Equifax = 'Equifax',
  Experian = 'Experian',
  TransUnion = 'TransUnion',
}

export enum CreditReportActionType {
  Submit = 'Submit',
  StatusQuery = 'StatusQuery',
}

export enum CreditReportResponseFormat {
  Xml = 'Xml',
  Pdf = 'Pdf',
  Html = 'Html',
}

export interface CreditReportBorrowerWithScores {
  ssn: string
  firstName: string
  lastName: string
  classificationType: BorrowerClassification
  scores?: CreditScoreSources
  creditReportId?: number
  liabilities?: any
}
