export async function promiseTimeout(
  promise: Promise<any>,
  timeoutInMs = 1000,
  timeoutReturnValue: any = null
) {
  let timeoutId

  const timeout: Promise<null> = new Promise((resolve) => {
    timeoutId = setTimeout(() => resolve(timeoutReturnValue), timeoutInMs)
  })

  const result = await Promise.race([promise, timeout])

  clearTimeout(timeoutId)

  return result
}
