export const replaceVariables = (messageString: string, values: any) => {
  if (values) {
    return messageString?.replace(/\{\{[\w_]+\}\}/g, function (substring) {
      const variable = substring.replace('{{', '').replace('}}', '')

      if (values[variable]) {
        return values[variable]
      }
      return variable
    })
  }

  return messageString
}
