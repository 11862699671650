export const demographicsQuestions = [
  {
    questionName: 'ethnicity',
    title: 'What is your ethnicity?',
    options: [
      {
        text: 'Hispanic or Latino',
        value: 'hispanicOrLatino',
        subtypeKey: 'hispanicSubtype',
        subtypeOptions: [
          { subtypeText: 'Mexican', subtypeValue: 'mexican' },
          { subtypeText: 'Puerto Rican', subtypeValue: 'puertoRican' },
          { subtypeText: 'Cuban', subtypeValue: 'cuban' },
          {
            subtypeText: 'Other Hispanic or Latino - Name origin:',
            subtypeValue: 'other',
            inputFieldEnabled: true,
            inputFieldKey: 'hispanicOtherSubtypeDesc',
          },
        ],
      },
      { text: 'Not Hispanic or Latino', value: 'notHispanicOrLatino' },
      {
        text: 'I do not wish to provide this information',
        value: 'declineToAnswer',
      },
    ],
    validations: [
      {
        rules: [
          {
            name: 'min',
            params:
              '[1, "Please select an option. If you do not wish to answer, select \'I do not wish to provide this information\'."]',
            component: 'ValidationRule',
          },
        ],
        component: 'Validation',
        validatorType: 'array',
      },
    ],
  },
  {
    questionName: 'race',
    title: 'What is your race?',
    options: [
      {
        text: 'American Indian or Alaskan Native or name of principal tribe:',
        value: 'americanIndianOrAlaskanNative',
        inputFieldEnabled: true,
        inputFieldKey: 'americanIndianTribeDesc',
      },
      {
        text: 'Asian',
        value: 'asian',
        subtypeKey: 'asianSubtype',
        subtypeOptions: [
          { subtypeText: 'Asian Indian', subtypeValue: 'asianIndian' },
          { subtypeText: 'Chinese', subtypeValue: 'chinese' },
          { subtypeText: 'Filipino', subtypeValue: 'filipino' },
          { subtypeText: 'Japanese', subtypeValue: 'japanese' },
          { subtypeText: 'Korean', subtypeValue: 'korean' },
          { subtypeText: 'Vietnamese', subtypeValue: 'vietnamese' },
          {
            subtypeText: 'Other Asian - Name race:',
            subtypeValue: 'other',
            inputFieldEnabled: true,
            inputFieldKey: 'asianOtherSubtypeDesc',
          },
        ],
      },
      { text: 'Black or African American', value: 'blackOrAfricanAmerican' },
      {
        text: 'Native Hawaiian or Other Pacific Islander',
        value: 'nativeHawaiianOrOtherPacificIslander',
        subtypeKey: 'pacificIslanderSubtype',
        subtypeOptions: [
          { subtypeText: 'Native Hawaiian', subtypeValue: 'nativeHawaiian' },
          {
            subtypeText: 'Guamanian or Champorro',
            subtypeValue: 'guamanianOrChamporro',
          },
          { subtypeText: 'Samoan', subtypeValue: 'samoan' },
          {
            subtypeText: 'Other Pacific Islander - Name race:',
            subtypeValue: 'other',
            inputFieldEnabled: true,
            inputFieldKey: 'pacificIslanderOtherSubtypeDesc',
          },
        ],
      },
      { text: 'White', value: 'white' },
      {
        text: 'I do not wish to provide this information',
        value: 'declineToAnswer',
      },
    ],
    validations: [
      {
        rules: [
          {
            name: 'min',
            params:
              '[1, "Please select an option. If you do not wish to answer, select \'I do not wish to provide this information\'."]',
            component: 'ValidationRule',
          },
        ],
        component: 'Validation',
        validatorType: 'array',
      },
    ],
  },
  {
    questionName: 'sex',
    title: 'What is your sex?',
    options: [
      { text: 'Female', value: 'female' },
      { text: 'Male', value: 'male' },
      {
        text: 'I do not wish to provide this information',
        value: 'declineToAnswer',
      },
    ],
    validations: [
      {
        rules: [
          {
            name: 'min',
            params:
              '[1, "Please select an option. If you do not wish to answer, select \'I do not wish to provide this information\'."]',
            component: 'ValidationRule',
          },
        ],
        component: 'Validation',
        validatorType: 'array',
      },
    ],
  },
]
