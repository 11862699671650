import sanitizeHtml from 'sanitize-html'

export function sanitizeString(string: string): string {
  if (typeof string !== 'string' || string.length === 0) {
    return string
  }
  // Remove all HTML tags
  return sanitizeHtml(string, {
    allowedTags: [],
    allowedAttributes: {},
  })
}

export function capitalizeFirstLetter(string) {
  if (typeof string !== 'string' || string.length === 0) {
    return string
  }

  return string[0].toUpperCase() + string.slice(1)
}

export function spaceOutPhoneDigits(string: string): string {
  return string
    .replace(/[^0-9]/gm, '') // Remove special chars
    .replace(/[0-9]/gm, '$& ') // Add a space between chars
}
