// Source: https://www.mortgagecalculator.org/helpful-advice/property-taxes.php
// Updated with 2023 data
export const StateEscrowFees = {
  AK: 1.04,
  AL: 0.4,
  AR: 0.64,
  AZ: 0.63,
  CA: 0.75,
  CO: 0.55,
  CT: 1.79,
  DC: 0.62,
  DE: 0.61,
  FL: 0.91,
  GA: 0.92,
  HI: 0.32,
  IA: 1.52,
  ID: 0.67,
  IL: 2.08,
  IN: 0.84,
  KS: 1.34,
  KY: 0.83,
  LA: 0.56,
  MA: 1.14,
  MD: 1.05,
  ME: 1.24,
  MI: 1.38,
  MN: 1.11,
  MO: 1.01,
  MS: 0.67,
  MT: 0.74,
  NC: 0.82,
  ND: 0.98,
  NE: 1.63,
  NH: 1.93,
  NJ: 2.23,
  NM: 0.67,
  NV: 0.59,
  NY: 1.4,
  OH: 1.59,
  OK: 0.89,
  OR: 0.93,
  PA: 1.49,
  RI: 1.4,
  SC: 0.57,
  SD: 1.17,
  TN: 0.67,
  TX: 1.68,
  UT: 0.57,
  VA: 0.87,
  VT: 1.83,
  WA: 0.87,
  WI: 1.61,
  WV: 0.57,
  WY: 0.56,
}

//Source: https://www.bankrate.com/insurance/homeowners-insurance/states/#home-insurance-rates-by-state
// Updated with 2024 data
export const StateHomeownersInsuranceAverage = {
  AK: 986,
  AL: 2745,
  AR: 2913,
  AZ: 2163,
  CA: 1480,
  CO: 3222,
  CT: 1720,
  DC: 1377,
  DE: 966,
  FL: 5527,
  GA: 2071,
  HI: 1191,
  IA: 2228,
  ID: 1282,
  IL: 2272,
  IN: 1724,
  KS: 4241,
  KY: 3277,
  LA: 4296,
  MA: 1660,
  MD: 1537,
  ME: 1227,
  MI: 1873,
  MN: 2578,
  MO: 2101,
  MS: 2820,
  MT: 2606,
  NC: 2435,
  ND: 2822,
  NE: 5652,
  NH: 980,
  NJ: 1161,
  NM: 2071,
  NV: 1097,
  NY: 1735,
  OH: 1316,
  OK: 4833,
  OR: 1015,
  PA: 1204,
  RI: 2064,
  SC: 2368,
  SD: 2838,
  TN: 2312,
  TX: 3916,
  UT: 1246,
  VA: 1546,
  VT: 806,
  WA: 1426,
  WI: 1176,
  WV: 995,
  WY: 1352,
}
