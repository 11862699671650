// CONVENTIONAL PRODUCTS
const conventionalCoverageRules = [
  {
    conditions: [
      {
        name: 'loanToValue',
        expression: '<=',
        value: 97,
      },
      {
        name: 'loanToValue',
        expression: '>',
        value: 95,
      },
      {
        name: 'productFamily',
        expression: '=',
        value: 'Conventional',
      },
      {
        name: 'loanTerm',
        expression: '>',
        value: 20,
      },
    ],
    result: {
      coveragePercent: 35,
    },
  },
  {
    conditions: [
      {
        name: 'loanToValue',
        expression: '<=',
        value: 95,
      },
      {
        name: 'loanToValue',
        expression: '>',
        value: 90,
      },
      {
        name: 'productFamily',
        expression: '=',
        value: 'Conventional',
      },
      {
        name: 'loanTerm',
        expression: '>',
        value: 20,
      },
    ],
    result: {
      coveragePercent: 30,
    },
  },
  {
    conditions: [
      {
        name: 'loanToValue',
        expression: '<=',
        value: 95,
      },
      {
        name: 'loanToValue',
        expression: '>',
        value: 90,
      },
      {
        name: 'productFamily',
        expression: '=',
        value: 'Conventional',
      },
      {
        name: 'loanTerm',
        expression: '<=',
        value: 20,
      },
    ],
    result: {
      coveragePercent: 25,
    },
  },
  {
    conditions: [
      {
        name: 'loanToValue',
        expression: '<=',
        value: 90,
      },
      {
        name: 'loanToValue',
        expression: '>',
        value: 85,
      },
      {
        name: 'productFamily',
        expression: '=',
        value: 'Conventional',
      },
      {
        name: 'loanTerm',
        expression: '>',
        value: 20,
      },
    ],
    result: {
      coveragePercent: 25,
    },
  },
  {
    conditions: [
      {
        name: 'loanToValue',
        expression: '<=',
        value: 90,
      },
      {
        name: 'loanToValue',
        expression: '>',
        value: 85,
      },
      {
        name: 'productFamily',
        expression: '=',
        value: 'Conventional',
      },
      {
        name: 'loanTerm',
        expression: '<=',
        value: 20,
      },
    ],
    result: {
      coveragePercent: 12,
    },
  },
  {
    conditions: [
      {
        name: 'loanToValue',
        expression: '<=',
        value: 85,
      },
      {
        name: 'loanToValue',
        expression: '>',
        value: 80,
      },
      {
        name: 'productFamily',
        expression: '=',
        value: 'Conventional',
      },
      {
        name: 'loanTerm',
        expression: '>',
        value: 20,
      },
    ],
    result: {
      coveragePercent: 12,
    },
  },
  {
    conditions: [
      {
        name: 'loanToValue',
        expression: '<=',
        value: 85,
      },
      {
        name: 'loanToValue',
        expression: '>',
        value: 80,
      },
      {
        name: 'productFamily',
        expression: '=',
        value: 'Conventional',
      },
      {
        name: 'loanTerm',
        expression: '<=',
        value: 20,
      },
    ],
    result: {
      coveragePercent: 6,
    },
  },
]

// HRHP PRODUCTS
const hrhpCoverageRules = [
  {
    conditions: [
      {
        name: 'loanToValue',
        expression: '<=',
        value: 97,
      },
      {
        name: 'loanToValue',
        expression: '>',
        value: 90,
      },
      {
        name: 'productFamily',
        expression: '=',
        value: 'HRHP',
      },
    ],
    result: {
      coveragePercent: 25,
    },
  },
  {
    conditions: [
      {
        name: 'loanToValue',
        expression: '<=',
        value: 90,
      },
      {
        name: 'loanToValue',
        expression: '>',
        value: 85,
      },
      {
        name: 'productFamily',
        expression: '=',
        value: 'HRHP',
      },
      {
        name: 'loanTerm',
        expression: '>',
        value: 20,
      },
    ],
    result: {
      coveragePercent: 25,
    },
  },
  {
    conditions: [
      {
        name: 'loanToValue',
        expression: '<=',
        value: 90,
      },
      {
        name: 'loanToValue',
        expression: '>',
        value: 85,
      },
      {
        name: 'productFamily',
        expression: '=',
        value: 'HRHP',
      },
      {
        name: 'loanTerm',
        expression: '<=',
        value: 20,
      },
    ],
    result: {
      coveragePercent: 12,
    },
  },
  {
    conditions: [
      {
        name: 'loanToValue',
        expression: '<=',
        value: 85,
      },
      {
        name: 'loanToValue',
        expression: '>',
        value: 80,
      },
      {
        name: 'productFamily',
        expression: '=',
        value: 'HRHP',
      },
      {
        name: 'loanTerm',
        expression: '>',
        value: 20,
      },
    ],
    result: {
      coveragePercent: 12,
    },
  },
  {
    conditions: [
      {
        name: 'loanToValue',
        expression: '<=',
        value: 85,
      },
      {
        name: 'loanToValue',
        expression: '>',
        value: 80,
      },
      {
        name: 'productFamily',
        expression: '=',
        value: 'HRHP',
      },
      {
        name: 'loanTerm',
        expression: '<=',
        value: 20,
      },
    ],
    result: {
      coveragePercent: 6,
    },
  },
]

const defaultCoverageRule = [
  {
    conditions: [],
    result: {
      coveragePercent: 12,
    },
  },
]

export const insuranceCoverageRules = [
  ...conventionalCoverageRules,
  ...hrhpCoverageRules,
  ...defaultCoverageRule,
]
