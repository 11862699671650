import { getValueFromPercentage, sumValues } from '@mortgage-pos/utils'

import {
  Fees,
  Section,
  MqApiRate,
  LoanPurpose,
  PropertyTaxResponse,
  LoanEstimateSections,
  ProductClass,
  EscrowOptions,
} from '@mortgage-pos/types'
import { CustomError } from '@mortgage-pos/error-handling'

const HOMEOWNERS_INSURANCE_MONTHS_DEFAULT = 2
const PROPERTY_TAX_MONTHS_DEFAULT = 2

export function buildLoanEstimateSections({
  rate,
  silkLineItems,
  monthlyPropertyTax,
  monthlyHomeownersInsurance,
  cashOut,
  rollInFees,
  propertyTaxDetails,
  pointsDecisioning,
  loanPurpose,
  sageClosingCreditOfferAmount,
  homeownersInsuranceMonths,
  escrowOptions,
}: {
  rate: MqApiRate
  silkLineItems: Fees[]
  monthlyPropertyTax: number
  monthlyHomeownersInsurance: number
  cashOut: number
  rollInFees: number
  propertyTaxDetails: PropertyTaxResponse
  pointsDecisioning: PointsDecisioning
  loanPurpose: LoanPurpose
  sageClosingCreditOfferAmount?: number
  homeownersInsuranceMonths?: number
  escrowOptions?: EscrowOptions
}): LoanEstimateSections {
  const sectionLetters = [
    'A',
    'B',
    'C',
    'D',
    'E',
    'F',
    'G',
    'H',
    'I',
    'J',
    'Others',
  ] as const

  const sections = sectionLetters.reduce<LoanEstimateSections>(
    (acc, section) => {
      acc[section] = { fees: [], total: 0 }
      return acc
    },
    {} as any
  )

  try {
    sections['A'] = retrieveSectionA(rate)
    sections['B'] = retrieveSectionB(rate)
    sections['C'] = retrieveSectionC(silkLineItems)
    sections['E'] = retrieveSectionE(silkLineItems)

    // Section F: Prepaid Total
    // Monthly amortization calculation for interest
    const dailyInterest = (rate.loanAmount * (rate.rate / 100)) / 365

    const interestTotal = dailyInterest * 15
    sections['F'].total += Number(interestTotal)

    sections['F'].fees.push({
      amount: Number(interestTotal.toFixed(2)),
      description: `Prepaid Interest ($${Number(dailyInterest).toFixed(
        2
      )} per day for 15 days @${rate.rate}%)`,
    })

    sections['F'].total = Number(sections['F'].total.toFixed(2))

    // Section G: Initial Escrow Payment at Closing.
    // Purchase should always have escrow, regardless of selected escrow options
    if (
      loanPurpose === LoanPurpose.Purchase ||
      (loanPurpose === LoanPurpose.Refinance &&
        escrowOptions !== EscrowOptions.TaxesOnly)
    ) {
      sections['G'].fees.push({
        amount:
          monthlyHomeownersInsurance *
          (homeownersInsuranceMonths || HOMEOWNERS_INSURANCE_MONTHS_DEFAULT),
        description: `Homeowner's Insurance $${
          monthlyHomeownersInsurance.toFixed(2) ?? '0.00'
        } per month for ${
          homeownersInsuranceMonths || HOMEOWNERS_INSURANCE_MONTHS_DEFAULT
        } mo.`,
      })

      sections['G'].total =
        (sections['G'].total || 0) +
        monthlyHomeownersInsurance *
          (homeownersInsuranceMonths || HOMEOWNERS_INSURANCE_MONTHS_DEFAULT)
    }

    // Property Tax details contain a custom months value
    if (escrowOptions !== EscrowOptions.InsuranceOnly) {
      if (
        propertyTaxDetails &&
        propertyTaxDetails?.propertyTaxMonthlyEstimate
      ) {
        sections['G'].fees.push({
          amount:
            Number(propertyTaxDetails.propertyTaxEstimate.toFixed(2)) || 0.0,
          description: `Property Taxes $${propertyTaxDetails?.propertyTaxMonthlyEstimate.toFixed(
            2
          )} per month for ${propertyTaxDetails.propertyTaxMonths} mo.`,
        })

        sections['G'].total =
          (sections['G'].total || 0) +
          propertyTaxDetails.propertyTaxMonthlyEstimate *
            propertyTaxDetails.propertyTaxMonths

        sections['G'].total = Number((sections['G'].total || 0).toFixed(2))
      } else {
        sections['G'].fees.push({
          amount: monthlyPropertyTax * PROPERTY_TAX_MONTHS_DEFAULT,
          description: `Property Taxes $${
            monthlyPropertyTax?.toFixed(2) ?? '0.00'
          } per month for ${PROPERTY_TAX_MONTHS_DEFAULT} mo.`,
        })

        sections['G'].total +=
          (monthlyPropertyTax || 0) * (PROPERTY_TAX_MONTHS_DEFAULT || 0)
        sections['G'].total = Number((sections['G'].total || 0).toFixed(2))
      }
    }

    // Section A: Update calculations based on options (Please see Points Decisioning)
    sections['A'] = hydratePoints(sections, rate, pointsDecisioning)

    // Section D: [“Total Loan Costs”:A + B + C]
    sections['D'].total += sumFeesTotals(sections)
    sections['D'].total = Number(sections['D']?.total.toFixed(2)) ?? 0

    sections['D'].fees.push({
      amount: sections['D'].total,
      description: 'Total Loan Costs',
    })

    // Sections H: Nothing for now

    // Section I: [“Total Other Costs”: E+F+G+H]
    sections['I'].total = 0
    sections['I'].total += sections['E']?.total ?? 0
    sections['I'].total += sections['F']?.total ?? 0
    sections['I'].total += sections['G']?.total ?? 0
    sections['I'].total += sections['H']?.total ?? 0
    sections['I'].total = Number(sections['I'].total.toFixed(2)) ?? 0

    sections['I'].fees.push({
      amount: sections['I'].total,
      description: 'Total Other Costs',
    })

    // Section J: [“Total Closing Costs”: Total Loan Costs + Total Other Costs - Lender Credits]
    if (rate?.lenderCredit) {
      sections['J'].fees.push({
        description: 'Lender Credit',
        amount: rate?.lenderCredit,
      })
    }

    sections['J'].fees.push({
      amount: sections['D'].total,
      description: 'Total Loan Costs',
    })

    sections['J'].fees.push({
      amount: sections['I'].total,
      description: 'Total Others Costs',
    })

    const isSageLender = rate?.loanProgram?.lenderName
      .toLowerCase()
      .includes('sage')
    const isNotJumbo = rate?.loanProgram?.class !== ProductClass.Jumbo

    if (isSageLender && isNotJumbo && sageClosingCreditOfferAmount) {
      sections['J'].fees.push({
        amount: -1 * sageClosingCreditOfferAmount,
        description: 'Sage Closing Credit Offer',
      })
    }

    sections['J'].total = sections['J'].fees.reduce(
      (fee, { amount }) => fee + (amount || 0),
      0
    )

    sections['J'].total = Number((sections['J'].total || 0).toFixed(2))

    //Total cash to close = Total closing costs - Roll in fees - Cash out
    sections['Others'].fees.push({
      description: 'Total Est. Payoffs and Payments',
      amount: sections['J'].total,
    })

    const isRefi = loanPurpose === LoanPurpose.Refinance
    const fundingFee = rate.governmentFees || 0
    sections['Others'].fees.push({
      description: isRefi ? 'Outstanding Balance' : 'Base Loan Amount',
      amount: rate.loanAmount - rollInFees - cashOut - fundingFee,
    })

    sections['Others'].fees.push({
      description: 'Loan Amount',
      amount: rate.loanAmount * -1,
    })

    if (
      fundingFee &&
      ['fha', 'va'].includes(rate.loanProgram?.family?.toLowerCase())
    ) {
      sections['Others'].fees.push({
        amount: fundingFee,
        description:
          rate.loanProgram?.family?.toLowerCase() == 'va'
            ? 'Funding Fee'
            : 'Upfront Mortgage Insurance Premium',
      })
    }

    sections['Others'].total = sections['Others'].fees.reduce(
      (fee, { amount }) => fee + (amount || 0),
      0
    )

    sections['Others'].total = Number(
      (sections['Others'].total || 0).toFixed(2)
    )

    return sections
  } catch (e) {
    const message = 'Mapping fees for loan estimate error'
    const log = {
      rate,
      silkLineItems,
      sections,
    }

    throw new CustomError(message, message, 500, log)
  }
}

function retrieveSectionA(rate: MqApiRate): Section {
  const sectionA: Section = {
    fees: [],
    total: 0,
  }

  rate.lenderBaseFees.forEach(function (feeEntry) {
    const sectionAHudlines = [801, 811, 812, 841]

    if (feeEntry.amount === 0) {
      return
    }

    if (sectionAHudlines.includes(feeEntry.hudLine)) {
      const amount =
        feeEntry.feeType === 'Percentage'
          ? getValueFromPercentage(rate.loanAmount, feeEntry.amount)
          : feeEntry.amount

      // Section A: Fees with a hudLine of 801, 811, 812, & 841 and points
      sectionA.total += amount
      sectionA.fees.push({
        amount,
        description: feeEntry.description,
        lineNumber: feeEntry.hudLine.toString(),
      })
    }
  })

  return sectionA
}

function retrieveSectionB(rate: MqApiRate): Section {
  const sectionB: Section = {
    fees: [],
    total: 0,
  }

  rate.lenderBaseFees.forEach(function (feeEntry) {
    if (feeEntry.amount === 0) {
      return
    }

    const excludeSectionBHudlines = [801, 811, 812, 840, 841]
    if (!excludeSectionBHudlines.includes(feeEntry.hudLine)) {
      // Section B: Everything from Loantek that is not 801, 811, 812, 840, & 841
      sectionB.total += feeEntry.amount
      sectionB.fees.push({
        amount: feeEntry.amount,
        description: feeEntry.description,
        lineNumber: feeEntry.hudLine.toString(),
      })
    }
  })

  return sectionB
}

function retrieveSectionC(silkLineItems: Fees[]): Section {
  const sectionC: Section = {
    fees: [],
    total: 0,
  }

  if (!silkLineItems) {
    return
  }

  silkLineItems.forEach(function (fee) {
    if (fee.amount === 0) {
      return
    }

    //Section C: All 1100s
    if (fee.lineNumber.startsWith('11')) {
      sectionC.total += fee.amount
      sectionC.fees.push({
        amount: fee.amount,
        description: fee.description,
        lineNumber: fee.lineNumber,
      })
    }
  })

  return sectionC
}

function retrieveSectionE(silkLineItems: Fees[]): Section {
  const sectionE: Section = {
    fees: [],
    total: 0,
  }

  if (!silkLineItems) {
    return
  }

  silkLineItems.forEach(function (fee) {
    if (fee.amount === 0) {
      return
    }

    //Section E: All 1200s
    if (fee.lineNumber.startsWith('12')) {
      sectionE.total += fee.amount
      sectionE.fees.push({
        amount: fee.amount,
        description: fee.description,
        lineNumber: fee.lineNumber,
      })
    }
  })

  return sectionE
}

export function buildOriginationFee(
  rate,
  pointsDecisioning: PointsDecisioning
) {
  if (rate?.discountPointsAsDollarAmount <= 0) {
    return []
  }

  const discountPoints = rate.discountPoints

  return [
    {
      amount: calculateDiscountPoints(rate, pointsDecisioning),
      description: `${discountPoints}% of Loan Amount (Points)`,
    },
  ]
}

export function calculateDiscountPoints(
  rate,
  pointsDecisioning: PointsDecisioning
) {
  if (rate?.discountPointsAsDollarAmount <= 0) {
    return rate?.discountPointsAsDollarAmount
  }

  // Create Empty Section A to fill with correct values
  // to satisfy the hydratePoints function
  const sections = {} as LoanEstimateSections
  sections['A'] = retrieveSectionA(rate)
  sections['A'] = hydratePoints(sections, rate, pointsDecisioning)

  // Set amount using hydratePoints value returned
  const amount: number = sections['A'].fees[0].amount
  return amount
}

export function calculateFewerUpfrontCosts(rate: MqApiRate): number {
  // List of hudlines for the desired fees. This excludes all the fees we
  // don't want to include in the final calculation
  const allowedHudlines = [801, 804, 809, 811, 812, 900]

  const filteredFees = rate.lenderBaseFees.filter((fee) => {
    return allowedHudlines.includes(fee?.hudLine)
  })

  const feeAmounts = filteredFees.map((fee) => fee.amount)
  feeAmounts.push(rate?.discountPointsAsDollarAmount ?? 0)

  return sumValues(feeAmounts)
}

export function sumFeesTotals(sections: LoanEstimateSections) {
  return sumValues([
    sections['A']?.total,
    sections['B']?.total,
    sections['C']?.total,
  ])
}

export function sumTaxAndPrepaidsTotals(sections: LoanEstimateSections) {
  return sumValues([sections['E']?.total, sections['F']?.total])
}

export function sumEscrowTotals(sections: LoanEstimateSections) {
  return sumValues([sections['G']?.total])
}

export interface PointsDecisioning {
  willWaiveEscrow: boolean
  willRollInEscrow: boolean
  willRollInFees: boolean
}

export function hydratePoints(
  sections: LoanEstimateSections,
  rate: MqApiRate,
  pointsDecisioning: PointsDecisioning
) {
  const sectionA = sections['A']

  if (rate?.discountPoints > 0) {
    const loanAmount = rate?.loanAmount ?? 0

    const { willWaiveEscrow, willRollInEscrow, willRollInFees } =
      pointsDecisioning

    const totalRolledInFees = willRollInFees ? sumFeesTotals(sections) : 0

    // Tax and prepaids are included as part of rolled in fees (even though
    // they're grouped under escrows/other costs in the breakdown)
    const totalRolledInTaxAndPrepaids = willRollInFees
      ? sumTaxAndPrepaidsTotals(sections)
      : 0

    const totalRolledInEscrows =
      willRollInEscrow && !willWaiveEscrow ? sumEscrowTotals(sections) : 0

    const discountPoints = rate.discountPoints
    const discountsPointsDecimal = rate.discountPoints / 100

    // Cashout amount is assumed to be included in the loanAmount for a Refinance when
    // we get the rate back from MQ
    const loanWithRollIns = sumValues([
      totalRolledInFees,
      totalRolledInTaxAndPrepaids,
      totalRolledInEscrows,
      loanAmount,
    ])

    const pointsAsDollarAmount = decimalize(
      loanWithRollIns * discountsPointsDecimal
    )

    sectionA.fees.unshift({
      amount: pointsAsDollarAmount,
      description: `${discountPoints}% of Loan Amount (Points)`,
    })

    sectionA.total =
      Math.round((sectionA.total + pointsAsDollarAmount) * 100) / 100
  }

  return sectionA
}

function decimalize(number) {
  return !isNaN(number) && !!number?.toFixed ? Number(number.toFixed(2)) : 0
}
