import { RequestContext } from './RequestContext'

export type MarketingPullResponse = {
  score: number
  response: any
}

export type CreditReportBodyResponse = {
  scores: Score
  mortgageRateEstimates?: MortgageRateEstimateData[]
  tradelines?: any
}

export type Score = {
  vantage3: number
}

export type MortgageRateEstimateData = {
  estimatedInterestRate: string
  openedDate: Date
  currentBalance: number
  loanAmount: number
  paymentScheduleMonthCount: number
  paymentDueAmount: number
  mortgageLoanType: string
  tradelinesIndex: number
}

export type CreditReportRequestArgs = {
  applicant: CreditReportApplicantData
  leadId?: number
  applicationId?: number
  requestContext?: Partial<RequestContext>
}

export interface CreditReport {
  id: number
  lead_id: number
  provider: string
  score: number
  credit_report: string
}

export interface CreditReportApplicantData {
  firstName: string
  lastName: string
  street: string
  city: string
  state: string
  zip: string
  ssn?: string
}

export enum CreditResponseLiabilityAcctType {
  CreditLine = 'CreditLine',
  Installment = 'Installment',
  Mortgage = 'Mortgage',
  Open = 'Open',
  Revolving = 'Revolving',
  Unknown = 'Unknown',
}

export interface CreditReportCompareScoresResponse {
  equifax: number
  experian: number
  transunion: number
}
