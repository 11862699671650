export type PropertyDetailsResponse = {
  corelogicPropertyId: string
  compositePropertyId: string
  lastUpdatedDate: string
  property: PropertyDetailsProperty
  ownership: PropertyDetailsOwnership
  building: PropertyDetailsBuilding
  taxAssessment: PropertyDetailsTaxAssessment
  site: PropertyDetailsSite
  location: PropertyDetailsLocation
  ownerTransfer: PropertyDetailsOwnerTransfer
  lastMarketSale: PropertyDetailsLastMarketSale
  priorSale: PropertyDetailsPriorSale
}

type PropertyDetailsProperty = {
  corelogicPropertyId: string
  compositePropertyId: string
  fipsCode: string
  apn: string
  latitude: number
  longitude: number
  universalParcelId: string
  parcelNumber: string
  parcelSequence: string
  houseNumber: string
  houseNumber2: string
  preDirection: string
  streetName: string
  streetSuffix: string
  postDirection: string
  unitNumber: string
  houseNumberSuffix: string
  city: string
  county: string
  zipcode: string
  zip4: string
  state: string
  carrierRoute: string
  streetAddress: string
  links: []
}

type PropertyDetailsOwnership = {
  corelogicPropertyId: string
  compositePropertyId: string
  ownerName1: string
  ownerName2: string
  vestingOwner1: string
  vestingOwner2: string
  vestingOwner3: null
  vestingOwner4: null
  mailingAddress: string
  mailingCityState: string
  mailingZip5: string
  mailingZip4: string
  mailingCarrierRoute: string
  mailingCareOfName: string
  phoneNumber: string
  vestingEtal: string
  vestingOwnershipRight: string
  ownerOccupiedInd: string
  pendingRecordInd: null
  corporateOwner: string
  ownerVestingCode: string
  principalFirstName: string
  principalCorporateName: string
  principalTitle: string
  principalLastName: string
  links: []
}

type PropertyDetailsBuilding = {
  corelogicPropertyId: string
  compositePropertyId: string
  stories: null
  secondFloorArea: null
  thirdFloorArea: null
  aboveGradeArea: null
  additionArea: null
  airConditioning: string
  attic: string
  mainArea: null
  basementArea: null
  basementFinish: string
  basementType: string
  fullBaths: string
  halfBaths: string
  bedrooms: string
  buildingComments: string
  carportArea: null
  condition: string
  constructionType: string
  exteriorWall: string
  finishedBasementArea: null
  fireplace: string
  fireplaceType: string
  floorCoverCode: string
  flooring: string
  foundation: string
  garage2Area: null
  garageArea: null
  garageCapacity: string
  grossArea: null
  groundFloorArea: null
  heatingFuelCode: string
  heatType: string
  interiorWallCode: string
  livingArea: number
  otherImprovements: string
  otherRooms: string
  parcelComments: string
  parcelFuel: string
  parkingSpaces: string
  parkingType: string
  patio1Area: null
  patioType: string
  pool: string
  poolArea: null
  porch1Area: null
  porch2Area: null
  porchCode: string
  quality: string
  rentableArea: null
  roofFrameCode: string
  roofShapeCode: string
  roofMaterialType: string
  roofTypeCode: string
  style: string
  totalBuildingArea: null
  totalBaths: number
  totalBathFixtures: null
  totalRooms: null
  upperArea: null
  yearBuilt: number
  effectiveYearBuilt: number
  fireplaceCount: null
  totalAdjustedLivingArea: null
  links: []
}

type PropertyDetailsTaxAssessment = {
  corelogicPropertyId: string
  compositePropertyId: string
  propertyTax: string
  totalAssessedValue: string
  totalTaxableValue: string
  landAssessment: string
  improvementValue: string
  marketValue: string
  assessmentValue: string
  taxYear: string
  equalizationRate: string
  delinquentYear: string
  taxAreaCode: string
  miscExemption: string
  seniorExemption: string
  disabledExemption: string
  homesteadExemption: string
  veteransExemption: string
  widowExemption: string
  governmentExemption: string
  religiousExemption: string
  agriculturalExemption: string
  schoolExemption: string
  charitableExemption: string
  utilitiesExemption: string
  taxExemptAmount: string
  fireDistrict: string
  garbageDistrict: string
  lightUtilityDistrict: string
  sewerDistrict: string
  waterDistrict: string
  taxAppraisalDistrict: string
  utilityDistrict: string
  communityCollegeDistrict: string
  elementarySchoolDistrict: string
  schoolDistrictName: string
  sixthGradeSchoolDistrict: string
  middleSchoolDistrict: string
  highSchoolDistrict: string
  localTaxDistrict: string
  improvedPercent: string
  propertyTaxAmount: string
  assessmentYear: string
  taxExemptions: string
  schoolDistrictCode: string
  links: []
}

type PropertyDetailsSite = {
  corelogicPropertyId: string
  compositePropertyId: string
  acres: number
  buildingClass: string
  buildingDepth: string
  buildingWidth: string
  commercialUnits: string
  landUse: PropertyDetailsLandUse
  lotSquareFeet: number
  lotDepth: string
  lotShape: string
  lotWidth: string
  numberOfBuildings: number
  residentialUnits: number
  sewerType: string
  siteInfluence: string
  stateLandUse: string
  topography: string
  usableLotArea: string
  water: string
  waterDistrict: string
  zoning: string
  countyUse: string
  countyUseCode: string
  stateLandUseCode: string
  zoningCode: string
  landUseCode: string
  links: []
}

type PropertyDetailsLocation = {
  corelogicPropertyId: string
  compositePropertyId: string
  alternateApn: string
  apn: string
  censusBlock: string
  county: string
  legalBlock: string
  legalDescription: string
  legalLot: string
  legalBookPage: string
  mapReference1: string
  mapReference2: string
  marketArea: string
  state: string
  subdivision: string
  township: string
  townshipRangeSection: string
  censusTract: string
  tractNumber: string
  schoolDistrictName: string
  censusBlockGroup: string
  municipalityName: string
  neighborhood: string
  neighborhoodCode: string
  schoolDistrict: string
  links: []
}

type PropertyDetailsOwnerTransfer = {
  corelogicPropertyId: string
  compositePropertyId: string
  recordingDate: string
  saleDate: string
  salePrice: null
  documentNumber: string
  deedType: string
  firstMortgageDocumentNumber: string
  ownerName1: string
  ownerName2: string
  landCourtNumber: null
  multiSplit: null
  partialInterest: null
  nominalMortgageDocumentNumber: string
  links: []
}

export type PropertyDetailsLastMarketSale = {
  corelogicPropertyId: string
  compositePropertyId: string
  firstMortgageAmount: number
  firstMortgageLoanType: string
  firstMortgageInterestRate: null
  firstMortgageInterestRateType: string
  secondMortgageAmount: null
  secondMortgageAmountType: string
  secondMortgageInterestRate: null
  secondMortgageInterestRateType: string
  actualSalePrice: number
  cashDown: string
  deedType: string
  documentNumber: string
  hawaiiCombinedSalePrice: null
  mortgageDocumentNumber: string
  firstMortgageLenderCode: string
  altDocumentNumber: string
  altDocumentNumber2: string
  partialInterest: null
  ownerName1: string
  ownerName2: string
  pricePerSquareFoot: number
  recordingDate: string
  saleDate: string
  saleType: string
  multipleOrSplitCode: string
  sellerName: string
  titleCompany: string
  transferDocumentNumber: string
  newConstruction: string
  salePrice: number
  lender: string
  links: []
}

type PropertyDetailsPriorSale = {
  corelogicPropertyId: string
  compositePropertyId: string
  documentNumber: string
  documentType: string
  lenderNameCode: string
  saleDate: string
  salePrice: number
  saleTypeCode1: string
  saleTypeCode2: string
  stampAmount: string
  recordingDate: string
  firstMortgageAmount: number
  firstMortgageType: string
  firstMortgageInterestRate: null
  firstMortgageInterestRateType: string
  links: []
}

export enum PropertyDetailsLandUse {
  Residential = 'RESIDENTIAL (NEC)',
  Townhouse = 'TOWNHOUSE/ROWHOUSE',
  ApartmentHotel = 'APARTMENT/HOTEL',
  Apartment = 'APARTMENT',
  Cabin = 'CABIN',
  Cooperative = 'COOPERATIVE',
  Condominium = 'CONDOMINIUM',
  CondominiumProject = 'CONDOMINIUM PROJECT',
  CommonArea = 'COMMON AREA',
  Duplex = 'DUPLEX',
  MidRiseCondo = 'MID RISE CONDO',
  HighRiseCondo = 'HIGH RISE CONDO',
  FratSororityHouse = 'FRAT/SORORITY HOUSE',
  ResidenceHallDormitories = 'RESIDENCE HALL/DORMITORIES',
  Hotel = 'HOTEL',
  ResortHotel = 'RESORT HOTEL',
  MultiFamily10UnitsPlus = 'MULTI FAMILY 10 UNITS PLUS',
  MultiFamily10UnitsLess = 'MULTI FAMILY 10 UNITS LESS',
  MultiFamulyDwelling = 'MULTI FAMILY DWELLING',
  MixedComplex = 'MIXED COMPLEX',
  MobileHome = 'MOBILE HOME',
  MobileHomeLot = 'MOBILE HOME LOT',
  MobileHomePark = 'MOBILE HOME PARK',
  MobileHomePP = 'MOBILE HOME PP',
  ManufacturedHome = 'MANUFACTURED HOME',
  Motel = 'MOTEL',
  Pud = 'PUD',
  Quadruplex = 'QUADRUPLEX',
  GroupQuarters = 'GROUP QUARTERS',
  Orphanage = 'ORPHANAGE',
  NursingHome = 'NURSING HOME',
  RuralHomesite = 'RURAL HOMESITE',
  SFR = 'SFR',
  TransientLodging = 'TRANSIENT LODGING',
  Triplex = 'TRIPLEX',
  TimeShare = 'TIME SHARE',
  TimeShareCondo = 'TIME SHARE CONDO',
  CommercialNec = 'COMMERCIAL (NEC)',
  AirRights = 'AIR RIGHTS',
  MultipleUses = 'MULTIPLE USES',
  AutoEquipment = 'AUTO EQUIPMENT',
  AutoRepair = 'AUTO REPAIR',
  AutoSales = 'AUTO SALES',
  SalvageImprv = 'SALVAGE IMPRV',
  AutoWrecking = 'AUTO WRECKING',
  BusinessPark = 'BUSINESS PARK',
  Carwash = 'CARWASH',
  CommercialBuilding = 'COMMERCIAL BUILDING',
  Cemetery = 'CEMETERY',
  CommercialCondominium = 'COMMERCIAL CONDOMINIUM',
  ConvalescentHospital = 'CONVALESCENT HOSPITAL',
  ConventionCenter = 'CONVENTION CENTER',
  DepartmentStore = 'DEPARTMENT STORE',
  Facilities = 'FACILITIES',
  StoreFranchise = 'STORE FRANCHISE',
  FastFoodFranchise = 'FAST FOOD FRANCHISE',
  FinInsuranceRealEstate = 'FIN/INSURANCE/REAL ESTATE',
  FinancialBuilding = 'FINANCIAL BUILDING',
  FuneralHome = 'FUNERAL HOME',
  Garage = 'GARAGE',
  Greenhouse = 'GREENHOUSE',
  Hospital = 'HOSPITAL',
  Kennel = 'KENNEL',
  AnimalHospitalVet = 'ANIMAL HOSPITAL/VET',
  LoftBuilding = 'LOFT BUILDING',
  MedicalBuilding = 'MEDICAL BUILDING',
  MedicalCondo = 'MEDICAL CONDO',
  Laboratory = 'LABORATORY',
  Laundromat = 'LAUNDROMAT',
  Nightclub = 'NIGHTCLUB',
  Bar = 'BAR',
  OfficeBuilding = 'OFFICE BUILDING',
  OfficeResidential = 'OFFICE & RESIDENTIAL',
  OfficeShowroom = 'OFFICE & SHOWROOM',
  OfficeCondo = 'OFFICE CONDO',
  ConvertedResidence = 'CONVERTED RESIDENCE',
  MiscImprovements = 'MISC IMPROVEMENTS',
  PreFabricatedBldg = 'PRE FABRICATED BLDG',
  MiscBuilding = 'MISC BUILDING',
  ParkingLot = 'PARKING LOT',
  ParkingStructure = 'PARKING STRUCTURE',
  ProduceMarket = 'PRODUCE MARKET',
  PublicStorage = 'PUBLIC STORAGE',
  RestaurantBuilding = 'RESTAURANT BUILDING',
  RestaurantDriveIn = 'RESTAURANT DRIVE IN',
  ServiceStation = 'SERVICE STATION',
  ServiceStationMarket = 'SERVICE STATION/MARKET',
  MiscCommercialServices = 'MISC COMMERCIAL SERVICES',
  ShoppingCenter = 'SHOPPING CENTER',
  StripCommercialCenter = 'STRIP COMMERCIAL CENTER',
  Apparel = 'APPAREL',
  StoreBuilding = 'STORE BUILDING',
  StoresOffices = 'STORES & OFFICES',
  StoresResidential = 'STORES & RESIDENTIAL',
  RetailTrade = 'RETAIL TRADE',
  Supermarket = 'SUPERMARKET',
  FoodStores = 'FOOD STORES',
  Tavern = 'TAVERN',
  Wholesale = 'WHOLESALE',
  LeasedLandBldg = 'LEASED LAND/BLDG',
  LeasedLandBldgII = 'LEASED LAND/BLDG II',
  IndustrialNec = 'INDUSTRIAL (NEC)',
  CommercialIndustrial = 'COMMERCIAL/INDUSTRIAL',
  Brewery = 'BREWERY',
  BulkPlant = 'BULK PLANT',
  Cannery = 'CANNERY',
  Chemical = 'CHEMICAL',
  TextileClothesCarpetIndust = 'TEXTILE/CLOTHES/CARPET INDUST',
  PaperAlliedIndustry = 'PAPER & ALLIED INDUSTRY',
  DumpSite = 'DUMP SITE',
  DurableGoods = 'DURABLE GOODS',
  NonDurableGoods = 'NON DURABLE GOODS',
  FoodProcessing = 'FOOD PROCESSING',
  GrainElevator = 'GRAIN ELEVATOR',
  HeavyIndustrial = 'HEAVY INDUSTRIAL',
  IndustrialCondominium = 'INDUSTRIAL CONDOMINIUM',
  IndustrialPark = 'INDUSTRIAL PARK',
  IndustrialPlant = 'INDUSTRIAL PLANT',
  LightIndustrial = 'LIGHT INDUSTRIAL',
  LumberYard = 'LUMBER YARD',
  LumberMill = 'LUMBER MILL',
  MetalProduct = 'METAL PRODUCT',
  MineralRights = 'MINERAL RIGHTS',
  MineralProcessing = 'MINERAL PROCESSING',
  MiniWarehouse = 'MINI WAREHOUSE',
  MultiTenantIndustrial = 'MULTI TENANT INDUSTRIAL',
  Packing = 'PACKING',
  Petroleum = 'PETROLEUM',
  MineQuarry = 'MINE/QUARRY',
  RDFacility = 'R&D FACILITY',
  TechnologicalIndustry = 'TECHNOLOGICAL INDUSTRY',
  Shipyard = 'SHIPYARD',
  Stockyard = 'STOCKYARD',
  Storage = 'STORAGE',
  StorageTanks = 'STORAGE TANKS',
  Warehouse = 'WAREHOUSE',
  Winery = 'WINERY',
  WineryII = 'WINERY II',
  VacantLandNec = 'VACANT LAND (NEC)',
  Marshland = 'MARSHLAND',
  BarrenLand = 'BARREN LAND',
  CommercialAcreage = 'COMMERCIAL ACREAGE',
  CommercialLot = 'COMMERCIAL LOT',
  CommonLand = 'COMMON LAND',
  Desert = 'DESERT',
  AgriculturalLand = 'AGRICULTURAL LAND',
  IndustrialAcreage = 'INDUSTRIAL ACREAGE',
  IndustrialLot = 'INDUSTRIAL LOT',
  MountainousLand = 'MOUNTAINOUS LAND',
  NaturalResources = 'NATURAL RESOURCES',
  MultiFamilyAcreage = 'MULTI FAMILY ACREAGE',
  MultiFamilyLot = 'MULTI FAMILY LOT',
  OpenSpace = 'OPEN SPACE',
  VacantMobileHome = 'VACANT MOBILE HOME',
  RecreationalAcreage = 'RECREATIONAL ACREAGE',
  ResidentialAcreage = 'RESIDENTIAL ACREAGE',
  ResidentialLot = 'RESIDENTIAL LOT',
  WasteLand = 'WASTE LAND',
  VacantLmtdNoDevPotential = 'VACANT LMTD/NO DEV POTENTIAL',
  WildlifeRefuge = 'WILDLIFE REFUGE',
  WildlifeRefugeII = 'WILDLIFE REFUGE II',
  AgriculturalNec = 'AGRICULTURAL (NEC)',
  Livestock = 'LIVESTOCK',
  NurseryHorticulture = 'NURSERY/HORTICULTURE',
  AgriculturalPlant = 'AGRICULTURAL PLANT',
  Ranch = 'RANCH',
  AnimalFarm = 'ANIMAL FARM',
  Farms = 'FARMS',
  AvocadoGrove = 'AVOCADO GROVE',
  CitrusGrove = 'CITRUS GROVE',
  DairyFarm = 'DAIRY FARM',
  FallowLand = 'FALLOW LAND',
  FieldSeed = 'FIELD & SEED',
  Fisheries = 'FISHERIES',
  Forest = 'FOREST',
  Greenbelt = 'GREENBELT',
  Orchard = 'ORCHARD',
  Pasture = 'PASTURE',
  PoultryRanch = 'POULTRY RANCH',
  TruckCrops = 'TRUCK CROPS',
  Vineyard = 'VINEYARD',
  VineyardII = 'VINEYARD II',
  PublicNec = 'PUBLIC (NEC)',
  TaxExempt = 'TAX EXEMPT',
  StateProperty = 'STATE PROPERTY',
  CountyProperty = 'COUNTY PROPERTY',
  MunicipalProperty = 'MUNICIPAL PROPERTY',
  PoliceFireCivilDefense = 'POLICE/FIRE/CIVIL DEFENSE',
  USPostalService = 'US POSTAL SERVICE',
  EmbassiesChanceries = 'EMBASSIES/CHANCERIES',
  CorrectionalFacility = 'CORRECTIONAL FACILITY',
  CommunityCenter = 'COMMUNITY CENTER',
  HistoricalDistrict = 'HISTORICAL DISTRICT',
  FederalProperty = 'FEDERAL PROPERTY',
  FederalBuilding = 'FEDERAL BUILDING',
  IndianReservation = 'INDIAN RESERVATION',
  Art = 'ART',
  MilitaryBuilding = 'MILITARY BUILDING',
  PossessoryInterest = 'POSSESSORY INTEREST',
  TaxAbatement = 'TAX ABATEMENT',
  EnterpriseZone = 'ENTERPRISE ZONE',
  School = 'SCHOOL',
  NurserySchool = 'NURSERY SCHOOL',
  HighSchool = 'HIGH SCHOOL',
  PrivateSchool = 'PRIVATE SCHOOL',
  VocationalTradeSchool = 'VOCATIONAL/TRADE SCHOOL',
  EducationalService = 'EDUCATIONAL SERVICE',
  SecEducationalSchool = 'SEC EDUCATIONAL SCHOOL',
  PublicSchool = 'PUBLIC SCHOOL',
  PublicService = 'PUBLIC SERVICE',
  CharitableOrganization = 'CHARITABLE ORGANIZATION',
  Religious = 'RELIGIOUS',
  University = 'UNIVERSITY',
  NatureFacility = 'NATURE FACILITY',
  Zoo = 'ZOO',
  RecreationalNec = 'RECREATIONAL (NEC)',
  Casino = 'CASINO',
  Amphitheatre = 'AMPHITHEATRE',
  AmusementArcade = 'AMUSEMENT ARCADE',
  AmusementPark = 'AMUSEMENT PARK',
  Auditorium = 'AUDITORIUM',
  BowlingAlley = 'BOWLING ALLEY',
  BilliardHall = 'BILLIARD HALL',
  Club = 'CLUB',
  CountryClub = 'COUNTRY CLUB',
  HealthClub = 'HEALTH CLUB',
  DanceHall = 'DANCE HALL',
  GolfCourse = 'GOLF COURSE',
  GolfRange = 'GOLF RANGE',
  Gymnasium = 'GYMNASIUM',
  LakeRiverBeach = 'LAKE/RIVER/BEACH',
  MarinaFacility = 'MARINA FACILITY',
  Park = 'PARK',
  RaceTrack = 'RACE TRACK',
  RacquetCourt = 'RACQUET COURT',
  TennisClub = 'TENNIS CLUB',
  RvPark = 'RV PARK',
  SkatingRink = 'SKATING RINK',
  Stadium = 'STADIUM',
  Stable = 'STABLE',
  SwimmingPool = 'SWIMMING POOL',
  DriveInTheater = 'DRIVE IN THEATER',
  Theater = 'THEATER',
  TouristAttractionExhibits = 'TOURIST ATTRACTION/EXHIBITS',
  LibraryMuseum = 'LIBRARY/MUSEUM',
  TheaterII = 'THEATER II',
  TransportNec = 'TRANSPORT (NEC)',
  CommunicationFacility = 'COMMUNICATION FACILITY',
  AircraftFacility = 'AIRCRAFT FACILITY',
  Airport = 'AIRPORT',
  ElectricalFacility = 'ELECTRICAL FACILITY',
  GasProduction = 'GAS PRODUCTION',
  MarineFacility = 'MARINE FACILITY',
  PortHarbor = 'PORT/HARBOR',
  RailroadFacility = 'RAILROAD FACILITY',
  RecordingStudio = 'RECORDING STUDIO',
  RadioFacility = 'RADIO FACILITY',
  Easement = 'EASEMENT',
  TelephoneFacility = 'TELEPHONE FACILITY',
  TVFacility = 'TV FACILITY',
  TransportFacility = 'TRANSPORT FACILITY',
  TruckTerminal = 'TRUCK TERMINAL',
  Utilities = 'UTILITIES',
  WasteDisposal = 'WASTE DISPOSAL',
  WellWater = 'WELL/WATER',
  WellGasOil = 'WELL/GAS/OIL',
  WellGasOilII = 'WELL/GAS/OIL II',
  RealPropertyNec = 'REAL PROPERTY (NEC)',
}
