export function getParams(url: string) {
  const params = {}
  const query = url.split('?')[1]

  const urlParams = new URLSearchParams(query)
  urlParams.forEach((value, key) => {
    params[key] = value
  })

  return params
}
