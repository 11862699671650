const defaultErrMsg = 'Something went wrong, Please try again'

const errorCodesMap: Record<string, string> = {
  invalid_password: 'Incorrect password, please try again.',
  password_dictionary_error:
    'This password may be easy to guess. Please try a more secure one.',
  password_no_user_info_error:
    'This password may be easy to guess. Please try a more secure one.',
  password_strength_error:
    'This password may be easy to guess. Please try a more secure one.',
  user_exists: `It looks like there's already an account associated with this email. Please try again or log in.`,
  username_exists: `It looks like there's already an account associated with this email. Please try again or log in.`,
  invalid_signup: 'Something went wrong, Please try again',
}

/**
 * @param errorCode is the auth response error code string
 * Function determines which error message string to return based on the error code
 * @returns string consisting of the correct error message
 */
export const processAuthError = (errorCode: string): string => {
  return errorCodesMap[errorCode] || errorCode || defaultErrMsg
}
