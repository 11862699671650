import { QuestionSet } from '@mortgage-pos/types'

export const questionSetIteration1: QuestionSet = {
  title: 'Sage Mortgage Beta',
  pages: [
    {
      name: 'citizenshipStop',
      type: 'stop',
      title: 'Check back soon!',
      subTitle: '',
      visibleIf: 'usCitizen = false',
      questions: [],
      description:
        "Sorry! We're currently only moving forward with United States Citizens for our Beta program - but that will change. Check back soon for our full Sage Mortgage rollout.",
    },
    {
      name: 'verifyEligible',
      type: 'page',
      title: "Let's get started",
      description: 'First, answer a few questions to confirm your eligibility.',
      subTitle: '',
      visibleIf: '',
      questions: [
        {
          questionName: { name: 'usCitizen' },
          title: 'Are you a U.S. Citizen?',
          toggled: 'true',
          options: [],
          content: '',
          subTitle: '',
          component: 'Question-Toggle',
          placeholder: '',
          validations: [],
        },
        {
          questionName: { name: 'existingHomeowner' },
          title: 'Are you an existing homeowner?',
          toggled: 'true',
          options: [],
          content: '',
          subTitle: '',
          component: 'Question-Toggle',
          placeholder: '',
          validations: [],
        },
        {
          questionName: { name: 'coBorrower' },
          title: 'Do you / will you have a co-borrower?',
          toggled: 'false',
          options: [],
          content: '',
          subTitle: '',
          inputType: '',
          component: 'Question-Toggle',
          placeholder: '',
          validations: [],
        },
      ],
    },
    {
      name: 'refiReason',
      type: 'page',
      title: '',
      subTitle: '',
      description: '',
      visibleIf: '',
      questions: [
        {
          questionName: { name: 'refiReason' },
          title: 'Why do you want to Refi?',
          component: 'Question-Radio',
          checked: '',
          options: [
            {
              text: 'Lower my monthly payment',
              value: 'Lower my monthly payment',
            },
            {
              text: 'Take cash out to pay for other expenses (home improvement, emergency fund, college tuition)',
              value:
                'Take cash out to pay for other expenses (home improvement, emergency fund, college tuition)',
            },
            {
              text: 'Pay off my existing mortgage sooner',
              value: 'Pay off my existing mortgage sooner',
            },
            {
              text: 'Consolidate high-interest debt into a single payment',
              value: 'Consolidate high-interest debt into a single payment',
            },
            {
              text: 'Other',
              value: 'Other',
            },
          ],
          content: '',
          subTitle: '',
          inputType: '',
          placeholder: '',
          validations: [
            {
              validatorType: 'string',
              rules: [
                {
                  name: 'required',
                  params: '["Please select an option"]',
                },
              ],
            },
          ],
        },
      ],
    },
    {
      name: 'propertyAddress',
      type: 'page',
      title: 'Tell us about the property you are refinancing',
      description:
        'This will help us give you more accurate information about things like your rate and maybe find you some discounts.',
      subTitle: '',
      visibleIf: '',
      questions: [
        {
          questionName: { name: 'street' },
          title: '',
          options: [],
          component: 'Question-Input',
          content: '',
          subTitle: 'Address',
          inputType: 'text',
          placeholder: 'Enter a location',
          validations: [
            {
              validatorType: 'string',
              rules: [
                {
                  name: 'required',
                  params: '["Street address required"]',
                },
                {
                  name: 'min',
                  params: '[4, "Please enter a valid address"]',
                },
              ],
            },
          ],
        },
        {
          questionName: { name: 'apt' },
          title: '',
          options: [],
          content: '',
          component: 'Question-Input',
          subTitle: 'Apt / Unit',
          inputType: 'text',
          placeholder: '',
          validations: [
            {
              validatorType: 'string',
              rules: [],
            },
          ],
        },
        {
          questionName: { name: 'city' },
          title: '',
          checked: '',
          options: [],
          content: '',
          subTitle: 'City',
          inputType: 'text',
          placeholder: '',
          component: 'Question-Input',
          validations: [
            {
              validatorType: 'string',
              rules: [
                {
                  name: 'required',
                  params: '["City required"]',
                },
                {
                  name: 'min',
                  params: '[3, "Please enter a valid city"]',
                },
              ],
            },
          ],
        },
        {
          questionName: { name: 'state' },
          title: 'State',
          checked: '',
          component: 'Question-Select',
          options: [
            {
              text: 'North Carolina',
              value: 'NC',
            },
            {
              text: 'South Carolina',
              value: 'SC',
            },
            {
              text: 'Not in the Carolinas',
              value: 'Not in the Carolinas',
            },
          ],
          content: '',
          subTitle: '',
          inputType: '',
          placeholder: '',
          validations: [
            {
              validatorType: 'string',
              rules: [
                {
                  name: 'required',
                  params: '["State required"]',
                },
              ],
            },
          ],
        },
        {
          questionName: { name: 'zipCode' },
          title: '',
          component: 'Question-Input',
          options: [],
          content: '',
          subTitle: 'Zip Code',
          inputType: 'text',
          placeholder: '',
          validations: [
            {
              validatorType: 'string',
              rules: [
                {
                  name: 'required',
                  params: '["Zipcode required"]',
                },
                {
                  name: 'min',
                  params: '[5, "Please enter a valid zipcode"]',
                },
              ],
            },
          ],
        },
      ],
    },
    {
      name: 'stateStop',
      type: 'stop',
      title: 'Check back soon!',
      subTitle: '',
      visibleIf: 'state = Not in the Carolinas',
      questions: [],
      description:
        "Sorry! We're currently only moving forward with loans in the Carolinas for our Beta program - but that will change. Check back soon for our full Sage Mortgage rollout.",
    },
    {
      name: 'propertyInfo',
      type: 'page',
      title: '',
      subTitle: '',
      visibleIf: '',
      questions: [
        {
          questionName: { name: 'propertyUse' },
          title: 'Tell us more about the property you are refinancing',
          checked: '',
          component: 'Question-Radio',
          options: [
            {
              text: 'Primary residence',
              value: 'PrimaryResidence',
            },
            {
              text: 'Second home',
              value: 'SecondaryOrVacation',
            },
            {
              text: 'Investment',
              value: 'InvestmentOrRental',
            },
          ],
          content: '',
          subTitle: '',
          inputType: '',
          placeholder: '',
          validations: [
            {
              validatorType: 'string',
              rules: [
                {
                  name: 'required',
                  params: '["Please select an option"]',
                },
              ],
            },
          ],
        },
        {
          questionName: { name: 'propertyType' },
          title: 'What property type would this be?',
          checked: '',
          component: 'Question-Radio',
          options: [
            {
              text: 'Single Family',
              value: 'SingleFamily',
            },
            {
              text: 'Townhouse or Condo',
              value: 'Townhouse',
            },
            {
              text: '2 to 4 units',
              value: 'MultiFamily2Units',
            },
          ],
          content: '',
          subTitle: '',
          inputType: '',
          placeholder: '',
          validations: [
            {
              validatorType: 'string',
              rules: [
                {
                  name: 'required',
                  params: '["Please select an option"]',
                },
              ],
            },
          ],
        },
        {
          questionName: { name: 'estimatedValue' },
          title: 'Estimated home value',
          checked: '',
          options: [],
          content: '',
          subTitle: 'Example: $250,000',
          component: 'Question-Input',
          inputType: 'number',
          placeholder: '',
          validations: [
            {
              validatorType: 'number',
              rules: [
                {
                  name: 'required',
                  params: '["Estimated home value required"]',
                },
                {
                  name: 'min',
                  params: '[50000, "Please enter a value over $50,000"]',
                },
              ],
            },
          ],
        },
      ],
      description: '',
    },
    {
      name: 'agreeToContinue',
      type: 'page',
      title: "Let's check your credit. Your score won't take a hit.",
      subTitle: '',
      visibleIf: '',
      questions: [],
      description:
        "The next step is a soft credit pull. Don't worry, this is a normal part of the procedure and will not lower your score at all. It just helps us figure out your general price range.",
    },
    {
      name: 'personalInfo',
      type: 'page',
      title: 'Tell us about yourself',
      subTitle: 'Personal Information',
      visibleIf: '',
      questions: [
        {
          questionName: { name: 'firstName' },
          title: '',
          component: 'Question-Input',
          options: [],
          content: '',
          subTitle: 'First name',
          inputType: 'text',
          placeholder: '',
          validations: [
            {
              validatorType: 'string',
              rules: [
                {
                  name: 'required',
                  params: '["First name required"]',
                },
              ],
            },
          ],
        },
        {
          questionName: { name: 'lastName' },
          title: '',
          checked: '',
          options: [],
          content: '',
          component: 'Question-Input',
          subTitle: 'Last name',
          inputType: 'text',
          placeholder: '',
          validations: [
            {
              validatorType: 'string',
              rules: [
                {
                  name: 'required',
                  params: '["Last name required"]',
                },
              ],
            },
          ],
        },
        {
          questionName: { name: 'phone' },
          title: '',
          checked: '',
          options: [],
          content: '',
          component: 'Question-Input',
          subTitle: 'Mobile phone',
          inputType: 'tel',
          placeholder: '',
          validations: [
            {
              validatorType: 'string',
              rules: [
                {
                  name: 'required',
                  params: '["Mobile phone number required"]',
                },
                {
                  name: 'phone',
                  params: '["Please enter a valid phone number"]',
                },
              ],
            },
          ],
        },
        {
          questionName: { name: 'email' },
          title: '',
          checked: '',
          options: [],
          content: '',
          component: 'Question-Input',
          subTitle: 'Email',
          inputType: 'email',
          placeholder: '',
          validations: [
            {
              validatorType: 'string',
              rules: [
                {
                  name: 'required',
                  params: '["Email address required"]',
                },
                {
                  name: 'email',
                  params: '["Please enter a valid email address"]',
                },
              ],
            },
          ],
        },
        {
          questionName: { name: 'lastFourSSN' },
          title: '',
          checked: '',
          options: [],
          content: '',
          component: 'Question-Input',
          subTitle: 'Last 4 digits SSN',
          inputType: 'text',
          placeholder: '',
          validations: [
            {
              validatorType: 'string',
              rules: [
                {
                  name: 'required',
                  params: '["Last 4 digits SSN required"]',
                },
                {
                  name: 'length',
                  params: '[4, "Please enter the last 4 digits of your SSN"]',
                },
              ],
            },
          ],
        },
        {
          questionName: {
            name: 'termsOfUsePrivacyPolicyAndElectronicConsentAgreement',
          },
          component: 'Question-Checkbox',
          title: '',
          checked: 'true',
          options: [],
          content:
            'I authorize Sage Mortgage to verify my credit. I\'ve also read and agreed to Sage Mortgage\'s <a href="https://sagemortgage.com/terms.html"  class="text-theme-highlight underline" target="_blank" rel="noopener noreferrer">Terms of Use</a>, <a href="https://sagemortgage.com/privacy.html" class="text-theme-highlight underline" target="_blank" rel="noopener noreferrer">Privacy Policy</a>, and <a href="https://sagemortgage.com/pdfs/e-sign-consent.pdf" class="text-theme-highlight underline" target="_blank" rel="noopener noreferrer">Consent to Receive Electronic Loan Documents</a>.',
          subTitle: '',
          inputType: '',
          placeholder: '',
          validations: [],
        },
      ],
      description:
        'This will help us give you more accurate information about things like your rate and maybe find you some discounts.',
    },
    {
      name: 'currentLoanInfo',
      type: 'page',
      title: '',
      subTitle: '',
      visibleIf: '',
      description: '',
      questions: [
        {
          questionName: { name: 'isCurrentProperty' },
          component: 'Question-Radio',
          title:
            'Are you currently making monthly payments on your current address?',
          checked: '',
          options: [
            {
              text: 'Yes',
              value: 'Yes',
            },
            {
              text: 'No',
              value: 'No',
            },
          ],
          content: '',
          subTitle: '',
          inputType: '',
          placeholder: '',
          validations: [
            {
              validatorType: 'string',
              rules: [
                {
                  name: 'required',
                  params: '["Please select an option"]',
                },
              ],
            },
          ],
        },
        {
          questionName: { name: 'remainingBalance' },
          title: 'Estimate the remaining balance on your loan',
          checked: '',
          component: 'Question-Input',
          options: [],
          content: '',
          subTitle: 'Example: $250,000',
          inputType: 'number',
          placeholder: '',
          validations: [
            {
              validatorType: 'number',
              rules: [
                {
                  name: 'required',
                  params: '["Estimated remaining required"]',
                },
                {
                  name: 'min',
                  params: '[50000, "Please enter a value over $50,000"]',
                },
              ],
            },
          ],
        },
      ],
    },
  ],
}
