import { NexussEndorsement } from '@mortgage-pos/types'

export const texasNexussEndorsements = (): NexussEndorsement[] => [
  {
    Name: 'T17 - PUD',
  },
  {
    Name: 'T19 - Restrictions Encroachments Minerals',
  },
  {
    Name: 'T30 - Tax Deletion',
  },
  {
    Name: 'T36 - EPL',
  },
  {
    Name: 'T42 - Equity Loan Mortgage',
  },
  {
    Name: 'T42.1 - Supplemental Equity Loan',
  },
  {
    Name: 'T28 - Condominium',
  },
  {
    Name: 'T31 - MHU',
  },
  {
    Name: 'T33 - Adj Rate',
  },
  {
    Name: 'T33.1 - Variable Rate Neg Amortization',
  },
  {
    Name: 'T43 - Reverse Mortgage',
  },
]

export const defaultNexussEndorsements = (): NexussEndorsement[] => [
  {
    Name: 'ALTA 8.1 - Environmental Protection Lien',
  },
  {
    Name: 'ALTA 9 - Restrictions Encroachments Minerals',
  },
  {
    Name: 'ALTA 14.3 - Future Advance Reverse Mortgage',
  },
  {
    Name: 'ALTA 22 - Location',
  },
  {
    Name: 'ALTA 4 - Condo',
  },
  {
    Name: 'ALTA 4.1 - Condo',
  },
  {
    Name: 'ALTA 5 - PUD',
  },
  {
    Name: 'ALTA 5.1 - PUD',
  },
  {
    Name: 'ALTA 6 - Variable Rate',
  },
  {
    Name: 'ALTA 6.2 - Variable Rate Negative Am',
  },
  {
    Name: 'ALTA 7 - Manufactured Housing',
  },
  {
    Name: "Lender's Survey Endorsement (Without Survey)",
  },
]
