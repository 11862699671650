// https://auth0.com/docs/deploy-monitor/logs/log-event-type-codes
export enum Auth0LogEventTypes {
  FailedLogin = 'f',
  FailedSilentAuth = 'fsa',
  SuccessChangeEmail = 'sce',
  SuccessChangePassword = 'scp',
  SuccessChangePasswordRequest = 'scpr',
  SuccessChangeUsername = 'scu',
  SuccessLogin = 's',
  SuccessLogout = 'slo',
  SuccessPostChangePasswordHook = 'scph',
  SuccessSignup = 'ss',
  SuccessSilentAuth = 'ssa',
}

export type SqsAuth0WebhookRequest = {
  method?: string
  'body-json'?: Auth0Body
  headers?: SqsAuth0WebhookHeaders
  queryParams?: unknown
  pathParams?: unknown
  context?: SqsAuth0WebhookRequestContext
}

export type SqsAuth0WebhookRequestContext = {
  'api-id': string
  'http-method': string
  stage: string
  'source-ip': string
  user: string
  'user-agent': string
  'user-arn': string
  'request-id': string
  'resource-id': string
  'resource-path': string
}

export type SqsAuth0WebhookHeaders = {
  Accept?: string
  'Accept-Encoding'?: string
  Authorization: string
  'Content-Type': string
  Host?: string
  'User-Agent'?: string
  'X-Amzn-Trace-Id'?: string
  'X-Forwarded-For'?: string
  'X-Forwarded-Port'?: string
  'X-Forwarded-Proto'?: string
}

export type Auth0Body = {
  log_id: string
  data: Auth0Log
}

export type Auth0Log = {
  client_id: string
  client_name: string
  connection: string
  connection_id: string
  date: string
  description: string
  details: {
    body: {
      client_id: string
      connection: string
      debug: boolean
      email: string
      tenant: string
      verify: boolean
    }
  }
  ip: string
  log_id: string
  strategy: string
  strategy_type: string
  type: string
  user_agent: string
  user_id: string
  user_name: string
}
