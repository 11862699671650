export type Condition = {
  name: string
  expression: string
  value: any
}

export type ConditionSet = {
  conditions: Condition[]
  result: any
}

export type EvaluatorInput = {
  inputData: any
  config: ConditionSet[]
}

export const evaluateRules = (input: EvaluatorInput) => {
  const { inputData, config } = input

  for (const conditionSet of config) {
    if (!conditionSet.conditions) return

    const match = conditionSet.conditions.reduce(
      (accumulator, currentValue) => {
        const matches = evaluate(inputData, currentValue)
        return accumulator && matches
      },
      true
    )

    if (match) {
      return conditionSet.result
    }
  }
}

const evaluate = (input, condition) => {
  switch (condition.expression) {
    case '=':
      return input[condition.name] == condition.value
    case '<':
      return input[condition.name] < condition.value
    case '>':
      return input[condition.name] > condition.value
    case '>=':
      return input[condition.name] >= condition.value
    case '<=':
      return input[condition.name] <= condition.value
    default:
      return false
  }
}
