import { RatesRequest } from '@mortgage-pos/types'

export const trimWhiteSpaces = (obj: any) => {
  Object.keys(obj).forEach((key) => {
    if (typeof obj[key] === 'string' || obj[key] instanceof String)
      obj[key] = obj[key].trim()
  })
  return obj
}

export const removeTrailingPunctuations = (obj: any) => {
  Object.keys(obj).forEach((key) => {
    if (typeof obj[key] === 'string' || obj[key] instanceof String)
      if (obj[key].slice(-1) === '.') obj[key] = obj[key].slice(0, -1)
  })
  return obj
}

export const ratesInputSanitization = (request: RatesRequest) => {
  request = trimWhiteSpaces(request)
  request = removeTrailingPunctuations(request)
  return request
}
