export function calculateDebtToIncome(
  totalExpenses: number,
  totalMonthlyIncome: number
) {
  const defaultDTI = 100

  const hardDTI = totalMonthlyIncome
    ? (totalExpenses / totalMonthlyIncome) * 100
    : defaultDTI

  // round to 2 decimal places
  return Math.floor((hardDTI + Number.EPSILON) * 100) / 100
}
