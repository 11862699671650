export function propertyTaxLookup(
  frequencyType: string
): PropertyTaxLookupData {
  switch (frequencyType) {
    case 'Annual':
      return {
        exemptionFrequency: 1,
        propertyTaxMonths: 14,
      }
      break
    case 'SemiAnnual':
      return {
        exemptionFrequency: 2,
        propertyTaxMonths: 8,
      }
    case 'TriAnnual':
      return {
        exemptionFrequency: 3,
        propertyTaxMonths: 6,
      }
    case 'Quarterly':
      return {
        exemptionFrequency: 4,
        propertyTaxMonths: 5,
      }
    case 'Monthly':
      return {
        exemptionFrequency: 12,
        propertyTaxMonths: 3,
      }
  }
}

interface PropertyTaxLookupData {
  exemptionFrequency: number
  propertyTaxMonths: number
}
