export const StateCityMap = {
  AK: {
    city: 'Anchorage',
    county: 'Anchorage',
    zip: '99501',
  },
  AL: {
    city: 'Birmingham',
    county: 'Jefferson',
    zip: '35222',
  },
  AR: {
    city: 'Little Rock',
    county: 'Pulaski',
    zip: '72209',
  },
  AZ: {
    city: 'Phoenix',
    county: 'Maricopa',
    zip: '85001',
  },
  CA: {
    city: 'Los Angeles',
    county: 'Los Angeles',
    zip: '90001',
  },
  CO: {
    city: 'Denver',
    county: 'Denver',
    zip: '80205',
  },
  CT: {
    city: 'Bridgeport',
    county: 'New Castle',
    zip: '06604',
  },
  DE: {
    city: 'Wilmington',
    county: 'Fairfield',
    zip: '19805',
  },
  FL: {
    city: 'Jacksonville',
    county: 'Duval',
    zip: '32250',
  },
  GA: {
    city: 'Atlanta',
    county: 'Fulton',
    zip: '30318',
  },
  HI: {
    city: 'Honolulu',
    county: 'Honolulu',
    zip: '96706',
  },
  IA: {
    city: 'Des Moines',
    county: 'Des Moines',
    zip: '52601',
  },
  ID: {
    city: 'Boise',
    county: 'Boise',
    zip: '83631',
  },
  IL: {
    city: 'Chicago',
    county: 'Cook',
    zip: '60601',
  },
  IN: {
    city: 'Indianapolis',
    county: 'Marion',
    zip: '46201',
  },
  KS: {
    city: 'Kansas City',
    county: 'Wyandotte',
    zip: '64102',
  },
  KY: {
    city: 'Louisville',
    county: 'Jefferson',
    zip: '40202',
  },
  LA: {
    city: 'New Orleans',
    county: 'Orleans',
    zip: '70139',
  },
  MA: {
    city: 'Boston',
    county: 'Suffolk',
    zip: '02101',
  },
  MD: {
    city: 'Baltimore',
    county: 'Baltimore',
    zip: '21204',
  },
  ME: {
    city: 'Portland',
    county: 'Cumberland',
    zip: '04102',
  },
  MI: {
    city: 'Detroit',
    county: 'Wayne',
    zip: '48211',
  },
  MN: {
    city: 'Minneapolis',
    county: 'Hennepin',
    zip: '55401',
  },
  MO: {
    city: 'Kansas City',
    county: 'Jefferson',
    zip: '64109',
  },
  MS: {
    city: 'Jackson',
    county: 'Jackson',
    zip: '39552',
  },
  MT: {
    city: 'Billings',
    county: 'Yellowstone',
    zip: '59102',
  },
  NC: {
    city: 'Charlotte',
    county: 'Mecklenburg',
    zip: '28208',
  },
  ND: {
    city: 'Fargo',
    county: 'Cass',
    zip: '58104',
  },
  NE: {
    city: 'Omaha',
    county: 'Douglas',
    zip: '68114',
  },
  NH: {
    city: 'Manchester',
    county: 'Hillsborough',
    zip: '03102',
  },
  NJ: {
    city: 'Newark',
    county: 'Essex',
    zip: '07102',
  },
  NM: {
    city: 'Albuquerque',
    county: 'Bernalillo',
    zip: '87122',
  },
  NV: {
    city: 'Las Vegas',
    county: 'Clark',
    zip: '89120',
  },
  NY: {
    city: 'New York City',
    county: 'New York',
    zip: '10001',
  },
  OH: {
    city: 'Columbus',
    county: 'Franklin',
    zip: '43201',
  },
  OK: {
    city: 'Oklahoma City',
    county: 'Oklahoma',
    zip: '73084',
  },
  OR: {
    city: 'Portland',
    county: 'Multnomah',
    zip: '97204',
  },
  PA: {
    city: 'Philadelphia',
    county: 'Philadelphia',
    zip: '19147',
  },
  RI: {
    city: 'Providence',
    county: 'Providence',
    zip: '02903',
  },
  SC: {
    city: 'Charleston',
    county: 'Charleston',
    zip: '29406',
  },
  SD: {
    city: 'Sioux Falls',
    county: 'Minnehaha',
    zip: '57101',
  },
  TN: {
    city: 'Nashville',
    county: 'Davidson',
    zip: '37201',
  },
  TX: {
    city: 'Houston',
    county: 'Harris',
    zip: '77080',
  },
  UT: {
    city: 'Salt Lake City',
    county: 'Salt Lake',
    zip: '84111',
  },
  VA: {
    city: 'Richmond',
    county: 'Chesterfield',
    zip: '23237',
  },
  VT: {
    city: 'Burlington',
    county: 'Chittenden',
    zip: '05401',
  },
  WA: {
    city: 'Seattle',
    county: 'King',
    zip: '98101',
  },
  WI: {
    city: 'Madison',
    county: 'Dane',
    zip: '53701',
  },
  WV: {
    city: 'Morgantown',
    county: 'Monongalia',
    zip: '26508',
  },
  WY: {
    city: 'Cheyenne',
    county: 'Laramie',
    zip: '82009',
  },
}
