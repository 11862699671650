export async function resolvePromisesInSequence<T>(
  promises: Promise<T>[]
): Promise<T[]> {
  const result: T[] = []

  for (const promise of promises) {
    const promiseResult = await promise
    result.push(promiseResult)
  }

  return result
}
